import React from "react"
import { mdiArrowRight } from "@mdi/js"

// Project components
import { Box, Button, Heading, Link, Wrapper } from "components"

const PromptSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.buttonLabel) && (
        <Wrapper id={id}>
          <Box>
            {/* Title */}
            {data.title && <Heading children={data.title} size={800} />}

            {/* Button Link */}
            {(data.buttonLabel || data.buttonTo) && (
              <Button
                as={Link}
                to={data.buttonTo}
                iconAfter={mdiArrowRight}
                intent="primary"
                appearance="primary"
                mt={["layout.4", "layout.6"]}
              >
                {data.buttonLabel}
              </Button>
            )}
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default PromptSection
