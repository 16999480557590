import React from "react"
import css from "@styled-system/css"

// Project components
import { Box, Flex, Heading, Item, Wrapper } from "components"

const ItemsSection = ({ data, id }) => {
  // Translate CMS colors into BG and Text color object, to be passed through to the individual item component

  let cardColor = {
    background: "",
    heading: "",
    text: "",
  }

  switch (data.color) {
    case "Blue":
      cardColor.background = "brand.primary"
      cardColor.heading = "background"
      cardColor.text = "background"
      break
    case "Green":
      cardColor.background = "brand.secondary"
      cardColor.heading = "background"
      cardColor.text = "background"

      break
    case "Gray":
      cardColor.background = "wash"
      cardColor.heading = "black"
      cardColor.text = "black"

      break
    default:
      cardColor.background = "black"
      cardColor.heading = "background"
      cardColor.text = "background"
  }

  return (
    <>
      {/* We only render the section if it has props set */}
      {data.items && (
        <Wrapper id={id}>
          {/* Section Header */}
          {data.title && (
            <Heading children={data.title} size={800} pb="layout.3" />
          )}
          <Box>
            {data.items && (
              <Flex
                flexWrap="wrap"
                mx={["-8px", -16]}
                my={["-8px", -16]}
                css={css({
                  "> *": {
                    width: ["50%", "33.333%"],
                    px: ["8px", 16],
                    py: ["8px", 16],
                  },
                })}
              >
                {data.items.map((item, index) => (
                  <Item {...item} cardColor={cardColor} key={"item" + index} />
                ))}
              </Flex>
            )}
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default ItemsSection
