import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { Divider, NextPage, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import ItemsSection from "./_itemsSection"
import PromptSection from "./_promptSection"

function BinOptionsPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicWhatGoesWhere {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          page_title {
            text
          }
          page_description {
            text
          }
          directory_title {
            text
          }
          directory_button_text
          directory_button_link {
            slug
            type
            url
          }
          items {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            name
            where
          }
          mixed_recycling_title
          mixed_recycling_color
          mixed_recycling {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            name
            where
          }
          organics_title
          organics_color
          organics {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            name
            where
          }
          garbage_title
          garbage_color
          garbage {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            name
            where
          }
          junk_title
          junk_color
          junk {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            name
            where
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
        }
      }
    }
  `)

  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })

  return (
    <>
      <SEO
        title={
          finalData.prismicWhatGoesWhere.data.seo_title &&
          finalData.prismicWhatGoesWhere.data.seo_title.text
        }
        description={
          finalData.prismicWhatGoesWhere.data.seo_description &&
          finalData.prismicWhatGoesWhere.data.seo_description.text
        }
        image={
          finalData.prismicWhatGoesWhere.data.seo_image &&
          finalData.prismicWhatGoesWhere.data.seo_image.url
        }
        imageAlt={
          finalData.prismicWhatGoesWhere.data.seo_image &&
          finalData.prismicWhatGoesWhere.data.seo_image.alt
        }
      />

      {/* ============ INTRO ============ */}
      <IntroSection
        id="whatGoesWhere-intro"
        data={{
          title:
            finalData.prismicWhatGoesWhere.data.page_title &&
            finalData.prismicWhatGoesWhere.data.page_title.text,
          subtitle:
            finalData.prismicWhatGoesWhere.data.page_description &&
            finalData.prismicWhatGoesWhere.data.page_description.text,
        }}
      />

      {/* Mixed Recycling */}
      <ItemsSection
        id="whatGoesWhere-mixedRecycling"
        data={{
          title: finalData.prismicWhatGoesWhere.data.mixed_recycling_title,
          color: finalData.prismicWhatGoesWhere.data.mixed_recycling_color,
          items:
            finalData.prismicWhatGoesWhere.data.mixed_recycling &&
            finalData.prismicWhatGoesWhere.data.mixed_recycling.map(
              ({ image, name, where }) => {
                return {
                  image: image && image.fluid && image,
                  name: name,
                  where: where,
                }
              }
            ),
        }}
      />

      {/* Organics */}
      <ItemsSection
        id="whatGoesWhere-organics"
        data={{
          title: finalData.prismicWhatGoesWhere.data.organics_title,
          color: finalData.prismicWhatGoesWhere.data.organics_color,
          items:
            finalData.prismicWhatGoesWhere.data.organics &&
            finalData.prismicWhatGoesWhere.data.organics.map(
              ({ image, name, where }) => {
                return {
                  image: image && image.fluid && image,
                  name: name,
                  where: where,
                }
              }
            ),
        }}
      />

      {/* Garbage */}
      <ItemsSection
        id="whatGoesWhere-garbage"
        data={{
          title: finalData.prismicWhatGoesWhere.data.garbage_title,
          color: finalData.prismicWhatGoesWhere.data.garbage_color,
          items:
            finalData.prismicWhatGoesWhere.data.garbage &&
            finalData.prismicWhatGoesWhere.data.garbage.map(
              ({ image, name, where }) => {
                return {
                  image: image && image.fluid && image,
                  name: name,
                  where: where,
                }
              }
            ),
        }}
      />

      {/* Junk */}
      <ItemsSection
        id="whatGoesWhere-junk"
        data={{
          title: finalData.prismicWhatGoesWhere.data.junk_title,
          color: finalData.prismicWhatGoesWhere.data.junk_color,
          items:
            finalData.prismicWhatGoesWhere.data.junk &&
            finalData.prismicWhatGoesWhere.data.junk.map(
              ({ image, name, where }) => {
                return {
                  image: image && image.fluid && image,
                  name: name,
                  where: where,
                }
              }
            ),
        }}
      />

      {/* ============ DIRECTORY PROMPT ============ */}
      <PromptSection
        id="whatGoesWhere-prompt"
        data={{
          title:
            finalData.prismicWhatGoesWhere.data.directory_title &&
            finalData.prismicWhatGoesWhere.data.directory_title.text,
          buttonLabel:
            finalData.prismicWhatGoesWhere.data.directory_button_text,
          buttonTo: finalData.prismicWhatGoesWhere.data.directory_button_link,
        }}
      />
      {finalData.prismicWhatGoesWhere.data.next_page_title &&
        finalData.prismicWhatGoesWhere.data.next_page_title.text && (
          <>
            {/* ============ DIVIDER ============ */}
            <Divider id="whatGoesWhere-divider" />

            {/* ============ NEXT PAGE ============ */}
            <NextPage
              id="whatGoesWhere-nextpage"
              image={finalData.prismicWhatGoesWhere.data.next_page_image}
              title={
                finalData.prismicWhatGoesWhere.data.next_page_title &&
                finalData.prismicWhatGoesWhere.data.next_page_title.text
              }
              buttonLabel={
                finalData.prismicWhatGoesWhere.data.next_page_button_text
              }
              buttonTo={
                "/" +
                finalData.prismicWhatGoesWhere.data.next_page_button_link.slug +
                "/"
              }
            />
          </>
        )}
    </>
  )
}

export default BinOptionsPage
